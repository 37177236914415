<template>
  <iframe :src="iframeSrc"></iframe>
</template>

<script>
export default {
  data() {
    return {
      iframeSrc: 'https://share.tcbi.qq.com/page/share?pageId=10537847&projectId=11025336&token=eb564de8-6e2a-4e86-abc0-d10da1875b20&scope=page&canvasType=GRID' // 替换为你想要嵌入的网站URL
    };
  }
};
</script>

<style>
/* 可以添加一些基本样式 */
iframe {
  width: 100%;
  height: 100%;
  border: none;
}
</style>